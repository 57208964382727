<template>
  <div class="comp-hot-box">
    <div class="JewelryMall-list">
      <div class="Goods_nav">
         <div class="nav-title">
          <div>选择饰品</div>
         </div>
      </div>
       <div class="Goods">
        <div class="Goods_box-list">
          <div class="Goods_list" >
            <div class="Goods_item" v-for="(item, index) in equipmentlist" :key="index"  :class="{ 'Goods_item-active': active === item }"  @click="handleBgChange(item)"   >
              <div v-if="item.odds_percent" class="box-price">
                <div class="dura_alias">
                  {{ item.odds_percent }}
                </div>
              </div>
              <div class="Goods_pic">
                <img :src="item.cover" alt="" />
              </div>
              <div class="elips1" style="font-size: 0.1rem;margin-top:0rem;margin-bottom:0.03rem;">{{ item.name }}</div>
              <div style="font-size: 0.1rem;margin-bottom:0.03rem;">
                {{ item.dura_alias == "无" ? "" : item.dura_alias }}
              </div>
              <div class="odds_percent">
                <div class="d-flex align-items-center">
                  <Hprice :size="0.1" :price="item.bean" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="luckypagination">
          <Pagination v-show="PageNum>0" :total="PageNum" :page.sync="page_num" :limit.sync="per_page" @pagination="handleCurrentChange" />
        </div>
    </div>
  
  </div>
</template>

<script>
import { luckyList} from "@/network/api.js";
import { mapMutations } from "vuex";
import { titleList, priceSearch } from "../../Lucky/index.js";
import leftFloat from "@/components/left-float/index.vue";
import GoodsList from "@/components/PubOpen/GoodsList.vue";
export default {
  name: "JewelryMall",
  components: {
    leftFloat,
    GoodsList
  },
  data() {
    return {
      active: 0,
      outidx: 0,
      smllidx: 0,
      loadingShow: true,
      outlist: [],
      equipmentlist: [],
      //分页
      per_page: 0,
      PageNum: 0,
      page_num: 1, //页码

      titleList,
      tabId: 0,
      priceSearch,
      current: 0,
      priceSearchIndex: undefined,
      receivedData: null

    };
  },
  filters: {
    GuoLvOne(str) {
      return str.length > 15 ? str.substr(0, 15) + "..." : str;
    },
  },
  created() {
    // this.GetluckyType();
    if (this.$route.query.TabIndex) {
      this.tabId = this.titleList[this.$route.query.TabIndex].id;
    } else {
      this.tabId = 0;
    }
    this.GetluckyList(this.tabId, 1);
  },

  methods: {
    priceInterval(price, i) {
      const p = price.split("-");
      this.min_num = p[0];
      this.max_num = p[1];
      this.priceSearchIndex = i;
    },
    currentChange(i) {
      this.current = i;
    },
     receiveDataFromChild(data) {
      this.receivedData = data;
      this.$emit('childDataData', this.receivedData);
    },
    //主分类选中
    tabIndexChange(id, index) {
      // console.log(123, id);
      this.tabId = id;
      this.GetluckyList(this.tabId);
      this.page_num = 1;
      this.$router.push(
        {
          path: '/Lucky',
          query: {
            TabIndex: index
          }
        }
      );

    },
    //装备列表
    GetluckyList(id, page) {
      this.loadingShow = true;
      luckyList(id, page).then((res) => {
        this.per_page = res.data.data.per_page;
        this.PageNum = res.data.data.total;
        this.equipmentlist = res.data.data.data;
      });
    },
    handleBgChange(item){
      this.active = item;
      this.$emit('childData', this.active);
      console.log('this.active',this.active)
    },
    handleCurrentChange(val) {
      this.GetluckyList(this.tabId, val.page);
    },
    //路由跳转开箱
    ToOpen(id) {
      this.$router.push({
        path: "/Lucky_open",
        query: {
          id: id,
        },
      });
    },

    //副分类选中
    smllSelect(index) {
      this.smllidx = index;
    },

    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },

    ...mapMutations(["User"]),
  },
  watch: {
    equipmentlist(val) {
      this.loadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.comp-hot-box {
  width: 102%;
}

.JewelryMall-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
    background: #1D1300;
    box-sizing: border-box;
    border: 0.01rem solid #69552D;
      .Goods_nav {
      width: 100%;
      height: 0.48rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      position: relative;
      background: url(../../../assets/newImages/list-title-bg.png) no-repeat;
      background-size: 100% 100%;

       }

       .Goods {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;

  .Goods_box-list {
    width: 98%;
    background: #1D1300;
    box-sizing: border-box;

    .Goods_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0.08rem;
      
      .Goods_item {
        width: 1.00rem;
        height: 1.12rem;
        padding: 0.03rem 0.05rem 0.05rem;
        margin: 0 0.032rem;
        text-align: center;
        color: #fff;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        margin-bottom: 0.08rem;
        background: url(../../../assets/newImages/goods-list-bg.png) no-repeat;
        background-size: 100% 100%;

        .box-price {
          display: flex;
          justify-content: right;
          align-items: center;
          margin-top: 0.05rem;
        }

        .odds_percent {
          font-size: 0.1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-align: left;
          box-sizing: border-box;
          color: #dea61f;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .dura_alias {
          box-sizing: border-box;
          color: #fff;
          font-size: 0.1rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-align: center;
        }

        .Goods_pic {
          width: 0.6rem;
          height: 0.46rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;
          margin: 0 auto;
          img {
            width: 100%;
          }
        }

        p {
          width: 100%;
          box-sizing: border-box;
          font-size: 0.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 0.18rem;
          margin-bottom: 0.08rem;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #75cd66;
          font-size: 0.1rem;
          margin: 5px 0 0;

          img {
            width: 0.2rem;
          }
        }
      }
      .Goods_item-active {
      background: url(../../../assets/newImages/box-active-bg.png) no-repeat;
      background-size: 100% 100%;
      }


    }
  }
}
}
.luckypagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.1rem auto;
}
.PageNum {
  width: 100%;

  li {
    background: #111111 !important;

    &.active {
      background: #4854c9 !important;
    }

    &:hover {
      color: #fff !important;
    }
  }

  .btn-prev,
  .btn-next {
    background: #111111;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .wrapper-box {
    z-index: 2001;
    width: 9rem;
    // height: 5.5rem;
    position: relative;
    background-image: url("../../../assets/images/other/jinkuang.png");
    background-size: 100% 100%;
    background-color: #131426;
    /*border: 1px solid #4854c9;*/
    /*background-color: #1e2355;*/
    /*border-radius: 0.08rem;*/
    overflow: hidden;
    padding: 0 0.35rem;
    box-sizing: border-box;

    .wrapper-box-top {
      width: 100%;
      height: 0.03rem;
    }

    .tit {
      width: 100%;
      font-size: 0.24rem;
      text-align: center;
      line-height: 0.8rem;
    }

    .other {
      width: 100%;
      height: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 0.2rem;

      .ShuLiang {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 0.3rem;
          height: 0.3rem;
          background: rgba(255, 255, 255, 0.08);
          text-align: center;
          line-height: 0.3rem;
          font-size: 0.16rem;
          cursor: pointer;

          &:nth-child(2) {
            width: 0.5rem;
            margin: 0 0.1rem;
          }
        }
      }

      .ZongJiaGe {
        display: flex;
        align-items: center;

        p {
          font-size: 0.18rem;
        }

        span {
          margin-top: 0.03rem;
          width: 0.7rem;
          display: flex;

          img {
            vertical-align: middle;
            width: 0.2rem;
            height: 0.2rem;
            margin-bottom: 0.02rem;
          }

          font-size: 0.18rem;
          color: #75cd66;
        }
      }
    }

    .pic {
      width: 2.75rem;
      height: 2.75rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      /*background: url(../../assets/images/PublicImg/item-bg.jpg) no-repeat center;*/
      border: 1px solid;
      border-image: linear-gradient(rgba(0, 0, 0, 0), #ecb105) 20 20;

      background-size: cover;

      img {
        max-width: 80%;
        max-height: 80%;
      }
    }

    .btns {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0.2rem;

      span {
        display: block;
        width: 3rem;
        height: 0.64rem;
        background: hsla(0, 0%, 100%, 0.06);
        /*border: 1px solid hsla(0, 0%, 100%, .2);*/
        color: #e4e4e4;

        text-align: center;
        line-height: 0.64rem;
        font-size: 0.14rem;
        margin-right: 0.2rem;
        cursor: pointer;
      }

      .btn-two {
        margin-right: 0;
        /*background: #ffd322;*/
        background-image: url("../../../assets/images/OpenBox/btn.jpg");
        background-size: 100% 100%;
        color: #fff;
      }
    }

    .info {
      margin-top: 0.3rem;
      text-align: center;
      font-size: 0.12rem;
      color: rgba(255, 255, 255, 0.53);
    }

    .cancel {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      line-height: 0.5rem;

      img {
        width: 0.15rem;
        cursor: pointer;
      }
    }
  }
}
</style>
